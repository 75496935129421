.presta-selection-container {
	background-color: var(--bg-color-grey-darker);
	padding: 1rem;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 5rem;
}

.presta-selection-card-container {
	position: relative;
	display: flex;
	max-height: 45vh;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 1rem;
	justify-content: space-between;
	width: 95vw;
}

.presta-title {
	display: flex;
	justify-content: flex-start;
	width: 100%;
}

.incident-selection-card-container {
	position: relative;
	display: flex;
	max-height: 35vh;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 1rem;
	justify-content: space-between;
	width: 95vw;
}

.presta-selection-button-container {
	position: sticky;
	background-color: #eaeaea;
	bottom: 0px;
	padding-bottom: 30px;
	display: flex;
	width: 100%;
	justify-content: center;
}

.presta-selection-card-container::-webkit-scrollbar-track {
	background: var(--bg-color-grey-darker);
}
