/* Containers */

.station-detail-pop-in-container > .modal-lg,
.modal-xl {
	width: 740px;
}

.station-detail-pop-in-container .modal-content {
	min-width: 740px;
	max-width: 740px;
	border: none;
}

.station-detail-pop-in-header {
	height: 60px;
	background-color: var(--bg-color-blue);
	color: var(--text-color-white);
	font-size: 22px;
	font-weight: bold;
}

.station-detail-pop-in-header::after {
	display: none;
}

.station-detail-table__container {
	display: grid;
	grid-template-columns: 120px 1fr repeat(1, 126px);
	grid-template-rows: min-content 1fr;
	grid-row-gap: 8px;
	grid-template-areas:
		'status unit end'
		'cards cards cards';
	margin-top: 37px;
}

.station-detail-table-title__status,
.station-detail-table-title__unit,
.station-detail-table-title__end {
	display: flex;
	align-items: center;
	font-size: 13px;
	color: var(--text-color-grey-60);
}

.station-detail-table-title__status {
	grid-area: status;
	padding-left: 30px;
	font-size: 13px;
	color: var(--text-color-grey-60);
}

.station-detail-table-title__unit {
	grid-area: unit;
}

.station-detail-table-title__end {
	grid-area: end;
}

.station-detail-table__cards {
	grid-area: cards;
}

.station-detail-card-container {
	display: grid;
	grid-template-columns: 123px 1fr repeat(1, [validate] 160px [end] 126px);
	grid-template-areas: 'status unit validate end';
	height: 72px;
	background: #ffffff;
	border: 1px solid #d7d7db;
	box-sizing: border-box;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 8px;
}

.station-detail-card__status,
.station-detail-card__unit,
.station-detail-card__validate,
.station-detail-card__end {
	display: flex;
	align-items: center;
}

.station-detail-card__status {
	grid-area: status;
	padding-left: 30px;
}

.station-detail-card__validate {
	grid-area: validate;
}

.station-detail-card__unit {
	grid-area: unit;
}
.station-detail-card__end {
	grid-area: end;
}

@media (max-width: 992px) {
	.station-detail-pop-in-container {
		overflow-y: hidden !important;
		padding: 0px;
	}
	.station-detail-pop-in-container .modal-content {
		top: 0;
		position: absolute;
		left: 0;
		max-width: none;
		min-width: 100vw;
		max-height: none;
		min-height: 100vh;
	}

	.station-detail-pop-in-container > .modal-lg,
	.modal-xl {
		width: 100vw;
		height: 100vh;
	}

	.station-detail-pop-in-container .modal-body {
		padding-left: 16px;
		padding-right: 16px;
	}
	.station-detail-pop-in-container .modal-dialog {
		position: unset;
		transition: none !important;
	}
	.station-detail-pop-in-header.modal-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		min-height: 10vh;
		max-height: 10vh;
		padding-top: 0px;
		padding-left: 50px;
	}

	.station-detail-pop-in-footer.modal-footer {
		min-height: 10vh;
		max-height: 10vh;
		justify-content: center !important;
		margin-right: 0px !important;
	}
	.station-detail-pop-in-header .h3 {
		font-size: 15px;
	}
	.show-mobile {
		display: block;
	}
	.action-close-arrow {
		cursor: pointer;
		padding: 0px 10px 0px 0px;
	}
}

@media (max-width: 992px) {
	.station-detail-table__container {
		display: grid;
		grid-template-columns: 100px 1fr repeat(1, 40px);
		grid-template-areas:
			'status unit end'
			'cards cards cards';
	}
}

@media (max-width: 992px) {
	.station-detail-card-container {
		display: grid;
		grid-template-columns: 100px;
		grid-template-areas: 'status unit validate end';
		height: auto;
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.station-detail-card-container > .station-detail-card__status {
		align-items: inherit;
	}

	.station-detail-card__unit-container-mobile > .station-detail-card__validate {
		margin-top: 18px;
	}

	.station-detail-card-container > .station-detail-card__end {
		align-items: inherit;
	}
}
