.scan-page {
  background-color: var(--bg-color-grey-darker);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.scan-page .code-link {
  position: absolute;
  bottom: 10%;
  width: auto;
}

.qr-code-text {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  line-height: 19px;
}
