.nav {
	background-color: var(--global-color-primary-darker);
}

.menu-items {
	color: var(--text-color-grey-lighter) !important;
	margin-left: 4px;
	font-weight: normal !important;
	font-size: 1rem !important;
	max-height: 8px !important;
}

#nav-tablet {
	height: var(--nav-height);
	justify-content: space-between;
	position: fixed;
	width: 100%;
	top: 0;
	padding-right: 1vw;
	padding-left: 1vw;
}

#nav-phone {
	height: var(--nav-height-phone);
	padding: 0 2%;
}

#last-sync-container {
	font-size: 13px;
	font-weight: 700;
	cursor: default;
}

.smart-tracer-phone-disconnect {
	font-size: 12px;
}

.sem-nav-title {
	font-size: 17px;
	color: #ffffff;
	margin-left: 32px;
}

/* responviness */

@media (max-width: 992px) {
	.count.card > .card-body > p.card-text {
		font-size: 0.9rem;
	}
}

@media (max-width: 576px) {
	#nav-tablet > a > .home-title,
	#nav-phone > a > .home-title {
		font-size: 14px;
	}
	.home-title {
		font-size: 14px;
	}
}
