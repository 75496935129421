.sem-card-container {
	display: grid;
	grid-template-columns: 150px 1fr repeat(2, 50px);
	grid-template-areas: 'status station unit end';
	height: 72px;
	background: #ffffff;
	border: 1px solid #d7d7db;
	box-sizing: border-box;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin-bottom: 8px;
}

.sem-card__status,
.sem-card__station,
.sem-card__unit,
.sem-card__end {
	display: flex;
	align-items: center;
}

.sem-card__status {
	grid-area: status;
	padding-left: 30px;
}

.sem-card__station {
	grid-area: station;
}

.sem-card__unit {
	grid-area: unit;
}
.sem-card__end {
	grid-area: end;
}

@media (max-width: 992px) {
	.sem-card-container {
		display: grid;
		grid-template-columns: 150px 1fr;
		grid-template-areas: 'status station';
		height: 72px;
	}

	.sem-card__unit,
	.sem-card__end {
		display: none;
	}
}
