.code-page {
	background-color: var(--bg-color-grey-darker);
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}
.identification-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	top: 60px;
	margin-top: 50px;
}
.code-btn-group {
	display: flex;
	justify-content: space-between;
	margin: 20px 33px 0 33px;
}

.code-link {
	width: 90%;
	margin: 0.3rem
}

