.sem-header-container {
	position: sticky;
	top: var(--nav-height);
	height: 74px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #ffffff;
	z-index: 5;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
	padding-left: 21px;
	padding-right: 21px;
}

.sem-header-line-selection {
	background: white;
}

.sem-selection {
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-right: 20px;
	font-weight: bold;
}

.sem-selection.cred-popin-select-text {
	font-weight: bold;
}

.sem-header-selection {
	margin: 0 20px;
}

.sem-header {
	height: 124px;
}

.sem-header-selection .credentials-popin-form-select .form-group {
	padding: 0;
	height: auto;
}

@media (max-width: 992px) {
	.sem-header-container {
		padding: 0;
		height: 56px;
	}

	.sem-header-line-selection {
		width: 100vw;
		overflow: scroll;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	.sem-header-line-selection::-webkit-scrollbar {
		display: none;
	}

	.sem-selection {
		font-size: 13px;
	}

	.sem-selection div:first-child {
		margin-right: 4px;
	}

	.sem-header-selection {
		margin: 0 2px;
	}
}
