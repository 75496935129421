.home-container {
	height: 100vh;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-image: url('../../../assets/icons/frames/home-background.svg');
	background-repeat: no-repeat;
	background-color: #0a007d;

	background-position: center center;
}

header .home-title {
	color: #ffffff;
	font-size: 24px;
	z-index: 1;
}

.home-footer {
	display: flex;
	color: #ffffff;
	font-size: 16px;
	max-width: 250px;
	text-transform: uppercase;
	flex-direction: column;
	align-items: center;
	position: relative;
	top: 140px;
}

.home-footer span:first-child {
	margin-bottom: 5px;
}

.header-container {
	z-index: 0;
	position: relative;
	bottom: 80px;
}

.content-toggle {
	cursor: pointer;
	position: absolute;
	display: inline-block;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	margin-top: 50px;
}
