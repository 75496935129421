.sem-selection-page {
  background-color: #ffffff;
  padding: 0 35px;
}

.sem-selection-page .main-title {
  color: var(--bg-color-jade);
  font-size: 22px;
  margin-top: 15px;
}

.sem-selection-buttons {
  margin-top: 30px;
  width: 100%;
}
