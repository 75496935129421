.card-action-container {
	width: 44vw;
	height: 80px;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	margin: 5px;
}

.border-service {
	border: 1px solid var(--bg-color-blue-dark);
}

.border-incident {
	border: 1px solid #d282be;
}

.card-action-container span {
	font-size: 12px;
	color: var(--bg-color-blue-dark);
	margin-top: 8px;
}

.card-action-selected {
	border: 1px solid var(--bg-color-blue-dark) !important;
	background-color: var(--bg-color-blue-dark);
}

.card-action-unselected {
	border: 1px solid var(--bg-color-grey-disabled) !important;
	background-color: var(--bg-color-grey-disabled);
}

.card-action-initial {
	background-color: white;
}

.card-incident-selected {
	border: 1px solid #d282be !important;
	background-color: var(--bg-color-blue-dark);
}

.card-incident-unselected {
	border: 1px solid #d282be !important;
	background-color: #dddddd;
}

.card-incident-initial {
	border: 1px solid #d282be !important;
	background-color: white;
}

.card-action-selected span {
	color: white;
}

.card-action-disable {
	border: none !important;
	background: #dddddd 0% 0% no-repeat padding-box !important;
}

.card-action-disable span {
	color: #b4b4b4;
}

.card-action-container-svg {
	margin-right: 16px;
	margin-left: 16px;
}
