.sem-confirmation-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 95px;
  background-color: var(--bg-color-jade) !important;
  color: #ffffff;
  border-color: var(--bg-color-jade) !important;
  border-radius: 20px;
  margin-bottom: 15px;
}

.sem-cancel-button {
  width: 100%;
  height: 66px;
  background-color: var(--bg-color-grey-40) !important;
  border-color: var(--bg-color-grey-40) !important;
  border-radius: 20px;
  color: #ffffff;
}

.sem-confirmation-button .sem-confirmation-button__icon {
  justify-self: flex-start;
  flex-grow: 0.2;
}

.sem-confirmation-button .sem-confirmation-button__text {
  flex-grow: 1;
  font-size: 28px;
  text-align: center;
}

.sem-cancel-button .sem-confirmation-button__text {
  font-size: 17px;
  text-align: center;
}
