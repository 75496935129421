.sem-page {
  position: absolute;
  background-color: var(--bg-color-grey);
  top: var(--nav-height);
  width: 100%;
  min-height: 100%;
}

.sem-container-nettoyage {
  padding: 0 60px 0 24px;
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
}

.sem-container-nettoyage-automated {
  padding: 0 60px 0 24px;
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: repeat(2, 1fr);
}

.sem-container-supplementaire {
  padding: 0 60px 0 24px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.sem-passage__title {
  font-weight: bold;
  margin: 30px 0 20px 0;
}

.sem-header-mobile-button-container {
  display: none;
}

/* ----- Responsiveness -----*/
@media (max-width: 1450px) {
  .sem-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
  }

  .sem-passage__title {
    display: none;
  }

  .sem-header-mobile-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 27px 0 17px 0;
    width: 100%;
    flex-direction: column;
  }

  .sem-header-mobile-button-container .selected {
    background-color: #0a0082;
    border-radius: 5px;
    color: #ffffff;
  }

  .sem-table-passage {
    width: 80%;
  }

  .sem-button-title {
    padding: 11px 22px 8px 22px;
    background-color: transparent;
    border: none;
    width: 100%;
  }

  .sem-header-mobile-button-passage-container {
    display: flex;
    flex-direction: row;
    margin-top: 21px;
    border: 1px solid #d7d7db;
    border-radius: 5px;
  }
}

@media (max-width: 992px) {
  .sem-table-passage {
    width: 100%;
  }
}
