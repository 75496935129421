.confirmation-page {
	margin-top: 0;
	background-color: var(--bg-color-grey-darker);
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
	padding: 1rem;
}

.sem-confirmation-page {
	background-color: #ffffff;
	padding: 0 35px;
}

.sem-confirmation-page .main-title {
	color: var(--bg-color-jade);
	font-size: 22px;
	margin-top: 15px;
}

.sem-confirmation-buttons {
	margin-top: 30px;
	width: 100%;
}

#animation-container {
	flex: 1;
	position: relative;
	width: 100%;
	margin-top: auto;
	font-weight: bold;
	font-size: 32px;
	text-align: left;
	line-height: 30px;
}

.action-icon-container {
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.svg-action-container {
	width: 150px;
	height: 100px;
	position: relative;
}

.text-left {
	text-align: start;
}

.incident-svg-container {
	display: flex;
}

/* breakpoints height  650 - 600 */

#splash-animation-container {
	position: relative;
	width: 70px;
	height: 70px;
}

#button-content-container {
	width: 200px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

#btn-done > *,
#btn-done svg,
#animation-container button {
	position: absolute;
}

/* button styling */

#animation-container button {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: white;
	outline: none !important;
}

#btn-done,
#btn-done:after {
	width: 268px;
	height: 194px;
	bottom: 10px;
	background: var(--text-color-green);
	outline: none;
}

#btn-not-done,
#btn-not-done:after {
	width: 212px;
	height: 154px;
	bottom: 230px;
	background: var(--text-color-orange);
}

#btn-done:after,
#btn-not-done:after {
	height: 100%;
}

#btn-not-done > span {
	padding-left: 1rem;
}

#btn-done > #thanks-text {
	font-weight: 200;
	top: 0px;
	left: 0;
	right: 0;
	width: 100px;
	text-align: center;
	margin: 15px auto;
}

#btn-done #splash-animation-container > #splash {
	top: -54px;
	right: -44px;
	margin: auto;
}

#btn-done > #splash-animation-container {
	margin: auto 0;
	top: 0;
	bottom: 0;
	left: 35px;
}

#btn-done > span:last-of-type {
	margin: auto 0;
	top: 0;
	right: 35px;
	bottom: 0;
	height: 32px;
}

@media (max-height: 680px) {
	#btn-done {
		height: calc(194px * 0.85);
	}
	#btn-done,
	#btn-done:after {
		width: calc(268px * 0.85);
	}

	#btn-not-done:after,
	#btn-not-done {
		width: calc(212px * 0.85);
		height: calc(154px * 0.85);
		bottom: 190px;
	}
}

@media (max-height: 650px) {
	#animation-container {
		font-size: 30px;
	}

	#btn-done {
		height: calc(194px * 0.8);
	}
	#btn-done,
	#btn-done:after {
		width: calc(268px * 0.8);
	}

	#btn-not-done:after,
	#btn-not-done {
		width: calc(212px * 0.8);
		height: calc(154px * 0.8);
		bottom: 200px;
	}
}

@media (max-height: 600px) {
	#btn-done {
		height: calc(194px * 0.7);
	}
	#btn-done,
	#btn-done:after {
		width: calc(268px * 0.7);
	}

	#btn-not-done:after,
	#btn-not-done {
		width: calc(212px * 0.7);
		height: calc(154px * 0.7);
		bottom: 150px;
	}
}
