.reporting-header-container {
	position: sticky;
	top: var(--nav-height);
	min-height: 10vh;
	background: #ffffff;
	z-index: 5;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	padding-left: 5%;
	padding-right: 5%;
}

.reporting-header-export-button-hidden {
	display: none !important;
}

.reporting-select {
	display: flex;
	justify-content: flex-start;
}

.reporting-select > * {
	width: 160px;
}

.trains-count-text {
	font-size: 28px;
	text-align: center;
}

.trains-count-subtitle {
	font-size: 17px;
	color: var(--text-color-grey);
	text-align: center;
	white-space: nowrap;
}

.reporting-header-export-button {
	display: flex;
	justify-content: flex-end;
}

@media (max-width: 900px) {
	.reporting-select {
		justify-content: center;
	}
	.reporting-header-export-button,
	.reporting-header-container {
		justify-content: center;
		align-items: center;
	}
	.reporting-header-container {
		padding-top: 2vh;
		min-height: 30vh;
	}
}
