@font-face {
	font-family: 'Parisine Ptf, Bold';
	font-weight: bold;
	src: local('Parisine Ptf, Bold'),
		url(../../fonts/PARISINEPTF-BOLD.OTF) format('opentype');
	font-display: swap;
}

@font-face {
	font-family: 'Parisine Ptf, Regular', Arial, Helvetica, sans-serif;
	font-weight: regular;
	src: local('Parisine Ptf'),
		url(../../fonts/PARISINEPTF-REGULAR.OTF) format('opentype');
	font-display: swap;
}

@font-face {
	font-family: 'Parisine Ptf, Italic';
	font-weight: italic;
	src: local('Parisine Ptf'),
		url(../../fonts/ParisinePtf-Italic.otf) format('opentype');
	font-display: swap;
}

@font-face {
	font-family: 'Monteserrat';
	src: local('Monteserrat'),
		url(../../fonts/Montserrat-Regular.ttf) format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat, Bold';
	font-weight: bold;
	src: local('Montserrat, Bold'),
		url('../../fonts/Montserrat-Bold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Ubuntu, Bold';
	font-weight: bold;
	src: local('Ubuntu, Bold'),
		url('../../fonts/Ubuntu-Bold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Lato, Bold';
	font-weight: bold;
	src: local('Lato, Bold'), url('../../fonts/Lato-Bold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'grover';
	src: local('Grover, Caps'),
		url('../../fonts/grover-caps.ttf') format('truetype');
	font-display: swap;
}
