.history-modal-info .history-checkbox-container {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.history-modal-info .history-checkbox-container:hover {
	background-color: rgb(0, 116, 217, 0.1);
	border-radius: 2px;
}

.history-modal-info .disabled:hover {
	background-color: transparent;
}

.history-checkbox-label {
	font-size: 12px;
}
