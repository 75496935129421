.history-modal-container {
	padding: 10px;
}

.history-modal-format-content-margin {
	margin-left: 75px;
}

.history-col {
	padding-right: 0px !important;
	padding-left: 15px !important;
}

.history-modal-info .service-item-checkbox {
	padding-left: 25px;
}

.flex-column {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}

.label-date-picker {
	font-size: 13px;
}

.history-modal-info {
	padding: 10px 0px 10px 0px;
}

.history-modal-trains {
	padding: 10px 0px 10px 0px;
}

.css-93ilmd-DropDown {
	width: 100% !important;
	margin-top: 0px !important;
	top: 35px !important;
}
.css-1jvqr3o-DropDown {
	top: 35px !important;
}
