#popup-root {
  position: absolute;
  top: 0;
  left: 0;
}

#popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

#popup {
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 17.5px 18.5px 29px 34px;
  border: 2px solid;
  z-index: 99;
}

.popup-pos-height {
  top: 60px;
}

.popup-header {
  position: relative;
  display: flex;
}

.popup-error {
  border-color: var(--text-color-pink);
  color: var(--text-color-pink);
  background-color: var(--bg-color-error);
}

.popup-succes {
  border-color: var(--text-color-green);
  color: var(--text-color-green);
  background-color: var(--bg-color-success);
}

.popup-danger {
  border-color: var(--text-color-orange);
  color: var(--text-color-orange);
  background-color: var(--bg-color-danger);
}

.message-text {
  white-space: pre-wrap;
  font-size: 14px;
  padding-top: 10px;
}

.title-text {
  padding-top: 10px;
  font-size: 18px;
  margin-bottom: 0px !important;
}

.close-icon {
  position: absolute;
  right: 15px;
  top: 8px;
  cursor: pointer;
  z-index: 99;
}

.container-dimension {
  width: 93vw;
  height: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-background {
  background-color: rgba(223, 247, 243, 0.8);
}

@media (max-width: 576px) {
  .container-dimension {
    min-width: 330px;
    max-width: 330px;
  }
  .popup-pos-height {
    top: 40px;
  }
}
