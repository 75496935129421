.reporting-card {
	background: #ffffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	margin: 0px 0px 16px 0px;
	width: 80%;
	padding: 16px;
	height: 140px;
}

.reporting-card__header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.reporting-card__text {
	display: flex;
	justify-content: space-between;
}

.reporting-card-title {
	font-size: 17px;
}

.reporting-card-title-group {
	display: flex;
	align-items: center;
}

.reporting-card-period {
	font-size: 15px;
}

.reporting-card-subtitle {
	font-size: 15px;
}

.reporting-card-progress-bar {
	position: relative;
}

.reporting-card .progress {
	height: 30px !important;
	border-radius: 15px !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	background: #b1b1b3;
}

.reporting-card .progress .progress-bar {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
	font-size: 16px;
	padding-right: 10px;
	text-align: end;
}

.reporting-card .progress .bg-success {
	background-color: #96be00 !important;
}

.reporting-card .progress .bg-warning {
	background-color: #ffbe00 !important;
}

.reporting-card .progress .bg-danger {
	background-color: #ff1400 !important;
}

.reporting-card-percentage {
	position: absolute;
	display: flex;
	height: 30px;
	font-size: 16px;
	align-items: center;
}

.reporting-card-progress-bar__prevision {
	height: 30px;
	display: flex;
	align-items: center;
}

.reporting-card-progress-bar__prevision p {
	height: 30px;
	border-right: 2px solid black;
	padding-right: 5px;
	text-align: right;
}

.reporting-card-advancement {
	height: 30px;
	margin-top: 4px;
}

.reporting-card-advancement .reporting-card-advancement__text {
	position: absolute;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 0 10px;
	font-size: 13px;
	font-weight: bold;
}

.reporting-card-advancement .reporting-card-advancement__bar {
	position: absolute;
	background-color: black;
	border-left: 1px black solid;
	height: 30px;
	width: 0;
}

@media (max-width: 992px) {
	.reporting-card {
		width: 95%;
	}

	.reporting-card__header {
		display: inherit;
	}

	.mb-5 {
		height: inherit;
	}
}
