.scanner-btn {
	width: 100%;
	height: 94px;
	background: var(--global-color-primary-darker) 0% 0% no-repeat padding-box;
	border-radius: 16px;
	opacity: 1;
	border: none;
}

.blue-card {
	display: flex;
	color: white;
	font-size: 16px;
	font-weight: bold;
}

.scanner-btn > .blue-card {
	justify-content: space-evenly;
}

.scanner-btn > .blue-card > .svg-container {
	width: 70px;
	height: 70px;
}
