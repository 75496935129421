.traking-header-container {
	position: sticky;
	top: var(--nav-height);
	height: 34vh;
	min-height: 120px;
	max-height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	z-index: 5;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}












