.notification-container {
	background-color: rgba(223, 247, 243, 0.8);
}

.notification-container .container-dimension {
	min-width: 600px;
	min-height: 111px;
	max-height: 300px;
}
.notification-container .popup-save {
	padding: 20px 20px 30px 20px;
}

.notification-container .popup-body .popin-button-group {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 20px;
}

.notification-container .popup-body {
	border-color: var(--text-color-blue);
	color: var(--text-color-blue);
	background-color: #ffffff;
}

.notification-container .popup-pos-height {
	top: 60px;
}

.notification-container .popup-body .popup-header {
	position: relative;
	display: flex;
}

.notification-container .popup-body .popup-message-text {
	height: 150px;
	white-space: pre-wrap;
	font-size: 14px;
	padding-top: 10px;
	color: #000000;
}

.notification-container .popup-body .title-text {
	padding-top: 10px;
	font-size: 18px;
	margin-bottom: 0px !important;
}

.close-icon {
	position: absolute;
	right: 15px;
	top: 8px;
	cursor: pointer;
	z-index: 99;
}

.notification-container .popup-body .popin-confirm-button {
	min-width: 60px;
	height: 36px;
	background: var(--global-color-primary-darker) 0% 0% no-repeat padding-box !important;
	border-radius: 4px;
	opacity: 1;
	border-color: var(--global-color-primary-darker) !important;
}

.custom-element-list {
	max-height: 140px;
	max-width: 500px;
	list-style-type: '- ';
	overflow: auto;
	columns: 4;
	-webkit-columns: 4;
	-moz-columns: 4;
}

@media (max-width: 576px) {
	.notification-container .popup-body .popin-button-group {
		width: 100%;
	}

	.notification-container .container-dimension {
		min-width: 330px;
		max-width: 330px;
	}
	.notification-container .popup-pos-height {
		top: 40px;
	}

	.custom-element-list {
		max-height: 100px;
		max-width: 340px;
		columns: inherit;
		-webkit-columns: inherit;
		-moz-columns: inherit;
	}
}
