.setting-notification__line {
	line-height: 2;
}

.notification__select__time__label {
	margin-right: 10px;
}

.notification___section-separator {
	margin: 15px 0 25px 0;
	width: 100%;
	border-top: 1px solid #ededf0;
}

.notification__roles__title {
	font-size: 13px;
	margin: 5px 0;
}
