.popup-save {
	padding: 20px 20px 30px 20px;
}

.popup-pos-height {
	top: 60px;
}

.popup-header {
	position: relative;
	display: flex;
}

.popup-save-setting {
	border-color: var(--text-color-blue);
	color: var(--text-color-blue);
	background-color: #ffffff;
}

.popup-save-message-text {
	white-space: pre-wrap;
	font-size: 14px;
	padding-top: 10px;
	color: #000000;
}

.title-text {
	padding-top: 10px;
	font-size: 18px;
	margin-bottom: 0px !important;
}

.close-icon {
	position: absolute;
	right: 15px;
	top: 8px;
	cursor: pointer;
	z-index: 99;
}

.container-setting-dimension {
	min-width: 367px;
	min-height: 111px;
}

.container-background {
	background-color: rgba(223, 247, 243, 0.8);
}

@media (max-width: 576px) {
	.container-dimension {
		min-width: 330px;
		max-width: 330px;
	}
	.popup-pos-height {
		top: 40px;
	}
}
