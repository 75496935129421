.setting-page__content {
	margin: 0 10% 2rem 10%;
	font-size: 13px;
}

.setting-page__title {
	font-size: 22px;
	margin: 42px 0 24px 0;
}

.setting-page__section-title {
	font-size: 17px;
	margin-bottom: 10px;
}

.setting-page__section-subtitle {
	font-size: 10px;
}

.setting-page__title-separator {
	width: 100%;
	border-top: 1px solid #b1b1b3;
	margin-bottom: 20px;
}

.fz-17 {
	font-size: 17px;
}

.fz-13 {
	font-size: 13px;
}
