.sem-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  color: var(--bg-color-blue);
  background-color: var(--bg-color-white) !important;
  border-color: var(--bg-color-blue) !important;
  border-radius: 16px;
  margin-bottom: 15px;
}

.sem-button .sem-button__icon {
  justify-self: flex-start;
}

.sem-button .sem-button__text {
  flex-grow: 1;
  font-size: 17px;
  text-align: start;
}
