.setting-monthly-service-container {
	padding-top: 20px;
	display: flex;
}

.monthly-service {
	max-width: 100px;
	margin-right: 16px;
}

.smtr-number-input {
	-moz-appearance: textfield;
}
