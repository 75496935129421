.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container button {
  border: 0px;
  width: 41px;
  height: 41px;
  position: absolute;
  right: 20px;
  top: calc(var(--nav-height-phone) + 10px);
  background: url('../../assets/icons/button/camera-toggle.svg');
}
