.square {
  width: 190px;
  height: 138px;
  border-radius: 16px;
  box-shadow: -1px 0px 31px #00000029;
  z-index: 2;
  align-self: center;
  display: flex;
  margin-top: -138px;
  justify-content: center;
  align-items: center;
}
.inclined-square {
  width: 190px;
  height: 138px;
  border-radius: 16px;
  transform: rotate(-6deg);
  z-index: 1;
  align-self: center;
  display: flex;
}
.card-button-container {
  z-index: 0;
  display: flex;
  flex-direction: column;
}
.text-card-button {
  font-size: 34px;
  text-transform: uppercase;
}
