.setting-page__np-alert-slider {
  width: 100%;
  margin-top: 20px;
}

.rc-slider-dot {
  display: none;
}

.rc-slider-tooltip-inner {
  background-color: white;
  color: black;
}

.col-center {
  text-align: center;
}

.col-large {
  padding: 0px;
}
