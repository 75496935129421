.code-btn {
  border: none;
  width: 350px;
  height: 94px;
  background: #0a0082 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

#code-btn-scanner {
  position: absolute;
  bottom: 5%;
}

.code-btn > .card-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.code-btn > .card-container > .svg-container {
  width: 70px;
  height: 70px;
}

.code-btn > .card-container > .code-button-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  white-space: pre-wrap;
}

@media (max-width: 576px) {
  .code-btn {
    width: 280px;
    height: 94px;
  }
}

@media (max-height: 992px) {
  .code-btn {
    width: 330px;
    height: 94px;
  }
}
