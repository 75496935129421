/* general-styling */
.landing-page-sem,
.landing-page {
	margin-top: 0;
	background-color: var(--bg-color-grey-darker);
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.landing-page {
	justify-content: space-evenly;
}

.landing-page-sem > h2 {
	font-size: 28px;
	color: var(--text-color-jade);
	font-weight: bold;
	margin-top: 55px;
}

.landing-page-sem #btn-qr-landing {
	margin-top: 55px;
}

.svg-container {
	background-color: white;
}

.qr-scanner-text {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
}
/* sem */

.landing-page-sem #btn-qr-landing,
.landing-page-sem #btn-qr-landing:after {
	background-color: #00AA91;
}
