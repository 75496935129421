.svg-line-1 {
	background: url('../../icons/metro/line-1.svg');
}
.svg-line-2 {
	background: url('../../icons/metro/line-2.svg');
}
.svg-line-3 {
	background: url('../../icons/metro/line-3.svg');
}
.svg-line-3b {
	background: url('../../icons/metro/line-3B.svg');
}
.svg-line-3-3b {
	background: url('../../icons/metro/line-3-3B.svg');
}
.svg-line-4 {
	background: url('../../icons/metro/line-4.svg');
}
.svg-line-5 {
	background: url('../../icons/metro/line-5.svg');
}
.svg-line-6 {
	background: url('../../icons/metro/line-6.svg');
}
.svg-line-7 {
	background: url('../../icons/metro/line-7.svg');
}
.svg-line-7b {
	background: url('../../icons/metro/line-7B.svg');
}
.svg-line-7-7b {
	background: url('../../icons/metro/line-7-7B.svg');
}
.svg-line-8 {
	background: url('../../icons/metro/line-8.svg');
}
.svg-line-9 {
	background: url('../../icons/metro/line-9.svg');
}
.svg-line-10 {
	background: url('../../icons/metro/line-10.svg');
}
.svg-line-11 {
	background: url('../../icons/metro/line-11.svg');
}
.svg-line-12 {
	background: url('../../icons/metro/line-12.svg');
}
.svg-line-13 {
	background: url('../../icons/metro/line-13.svg');
}
.svg-line-14 {
	background: url('../../icons/metro/line-14.svg');
}
.svg-line-15 {
	background: url('../../icons/metro/line-15.svg');
}
.svg-line-16 {
	background: url('../../icons/metro/line-16.svg');
}
.svg-line-17 {
	background: url('../../icons/metro/line-17.svg');
}
.svg-line-18 {
	background: url('../../icons/metro/line-18.svg');
}
.svg-line-mts {
	background: url('../../icons/metro/line-mts.svg');
}
.svg-line-a {
	background: url('../../icons/rer/line-a.svg');
}
.svg-line-b {
	background: url('../../icons/rer/line-b.svg');
}
.svg-line-rer {
	background: url('../../icons/rer/rer.svg');
}

.svg-line-sem {
	background: url('../../icons/sem/sem.svg');
}

.svg-line-container-small {
	width: 25px;
	height: 25px;
}

.svg-line-container-medium {
	width: 35px;
	height: 35px;
}

.svg-line-container-large {
	width: 42px;
	height: 42px;
}
