.sem-table__container {
  display: grid;
  grid-template-columns: 150px 1fr repeat(2, 50px);
  grid-template-rows: min-content 1fr;
  grid-row-gap: 8px;
  grid-template-areas:
    'status station unit end'
    'cards cards cards cards';
}

.sem-table-title__status,
.sem-table-title__station,
.sem-table-title__unit,
.sem-table-title__end {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--text-color-grey-60);
}

.sem-table-title__status {
  grid-area: status;
  padding-left: 30px;
  font-size: 13px;
  color: var(--text-color-grey-60);
}

.sem-table-title__station {
  grid-area: station;
}

.sem-table-title__unit {
  grid-area: unit;
}

.sem-table-title__end {
  grid-area: end;
}

.sem-table__cards {
  grid-area: cards;
}

@media (max-width: 992px) {
  .sem-table__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'cards';
  }

  .sem-table-title__unit,
  .sem-table-title__end,
  .sem-table-title__station,
  .sem-table-title__status {
    display: none;
  }
}
