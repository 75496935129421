.incidents-list-container {
	display: flex;
	flex-direction: column;
	padding-top: 24px;
	padding-bottom: 24px;
	align-items: center;
	.incident-toggle {
		width: 80%;
		height: 100%;
		display: flex;
		font-size: 15px;
		padding-left: 2rem;
		justify-content: flex-start;
		margin-bottom: 10px;
	}

	.incidents-list-title {
		align-self: flex-start;
		margin-left: 10%;
	}

	.incident-toggle:hover {
		background-color: rgb(96, 93, 128);
	}

	.incident-name-container {
		margin-left: 15px;
	}

	.incident-selected {
		background-color: rgba(10, 0, 130, 1);
		color: white;
	}
}

.incident-header {
	display: flex;
	align-items: center;
	box-shadow: 0 4px 6px -6px #222;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 16px;
	font-size: 24px;
}

.incident-header-train-number {
	padding-left: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (max-width: 992px) {
	.incidents-list-container {
		padding-top: 12px;
		padding-bottom: 12px;
		min-height: 55vh;
		max-height: 55vh;
	}
}
