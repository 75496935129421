.reporting-card__unit {
  min-height: 150px;
  height: auto;
}

.reporting-card-subtitle {
  font-size: 13px;
}

.reporting-card__row {
  height: 72px;
  background: #ffffff;
  border: 1px solid #d7d7db;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 0 16px 8px 16px;
}

.reporting-card__unit .reporting-card__text {
  margin-bottom: 30px;
}

.reporting-card__container .reporting-card__header {
  margin: 0 29px 8px 16px;
}

.reporting-card-unit-header {
  font-size: 13px;
  color: var(--text-color-grey-60);
}

.reporting-card__unit-name,
.reporting-card__station,
.reporting-card__passage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.reporting-card__station,
.reporting-card__station-header {
  padding-left: 50px;
}

.reporting-card__station .reporting-card__unit-name-mobile {
  font-size: 12px;
}

.reporting-card__unit-name,
.reporting-card__passage {
  font-size: 15px;
}

.reporting-card__footer {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.reporting-card__footer .reporting-card__footer-button {
  padding: 6px 20px;
  height: 32px;
  width: auto;
  line-height: normal;
}

@media (max-width: 576px) {
  .reporting-card__station,
  .reporting-card__station-header {
    padding-left: 20px;
  }
  .reporting-card__row {
    margin: 0 0px 8px 0px;
  }
}
