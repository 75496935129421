.delete-service-table th,
.delete-service-table thead th {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 0px !important;
}

.delete-service-table tbody + tbody {
	padding-left: 10px !important;
}

.delete-service-table td {
	padding-left: 20px !important;
}
