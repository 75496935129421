html {
	font-size: 16px;
}

.title-big {
	font-size: 3.5rem;
	margin-top: 4px;
}

.smart-tracer-phone {
	font-size: 14px;
}

.smart-tracer-tablet {
	font-size: 24px;
}

h1,
.h1 {
	font-size: 2.125rem;
}

h2,
.h2 {
	font-size: 2rem;
}

h3,
.h3 {
	margin: 0;
	font-size: 1.5rem;
}

h4,
.h4 {
	font-size: 1rem;
}

h5,
.h5 {
	margin: 0;
	font-size: 0.875rem;
}

p,
.p {
	margin: 0;
	padding: 0;
	font-size: 0.75rem;
}

.font-paris {
	font-family: 'Parisine Ptf', Arial, Helvetica, sans-serif;
}

.font-paris-lighter {
	font-family: 'Parisine Ptfcl, bold', Arial, Helvetica, sans-serif;
}

.font-paris-italic {
	font-style: italic;
}

.font-paris-bold {
	font-weight: bold;
}

.font-montserrat {
	font-family: 'Monteserrat', Arial, Helvetica, sans-serif;
}

.font-montserrat-light {
	font-family: 'Monserrat, Light', sans-serif;
}

.font-montserrat-extralight {
	font-family: 'Monserrat, Extra-light', sans-serif;
}

.font-montserrat-thin {
	font-family: 'Monserrat, Thin', sans-serif;
}

.font-montserrat-bold {
	font-family: 'Montserrat, Bold', Arial, Helvetica, sans-serif;
}

.font-ubuntu-bold {
	font-family: 'Ubuntu, Bold', Arial, Helvetica, sans-serif;
}

.font-lato-bold {
	font-family: 'Lato, Bold', sans-serif;
}

.font-grover {
	font-family: 'grover', Arial, Helvetica, sans-serif;
}

.font-grey {
	color: var(--text-color-grey);
}

.font-blue {
	color: var(--text-color-blue);
}
.font-pink {
	color: var(--text-color-pink);
}

.font-green {
	color: var(--text-color-green);
}

.font-orange {
	color: var(--text-color-orange);
}

.font-black {
	color: black;
}

.font-white {
	color: white !important;
}

.font-grey-lighter {
	color: var(--text-color-grey-lighter);
}

.font-grey-darker {
	color: var(--text-color-grey-darker);
}

.font-light-black {
	color: var(--text-color-light-black);
}

@media (max-width: 1199px) {
	html {
		font-size: 14px;
	}

	.title-big {
		font-size: 3.1rem;
	}
}
