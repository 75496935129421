.reporting-line-selector-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 68px;
	height: 68px;
	border: none;
	border-radius: 0;
	background-color: var(--bg-color-grey) !important;
	outline: none !important;
	cursor: pointer;
}

.reporting-line-selector-button__selected {
	background-color: #ffffff !important;
	box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.1);
	z-index: 1;
}

.reporting-svg-metro-container {
	width: 35px;
	height: 35px;
}

.logo-dropdown {
	height: 24px;
	width: 24px;
	margin-left: 1rem;
	margin-right: 1rem;
}
@media (max-width: 992px) {
	.reporting-line-selector-n {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.reporting-line-selector-button-n {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 68px;
		height: 68px;
		border: none;
		border-radius: 0;
		background-color: var(--bg-color-grey) !important;
		outline: none !important;
	}

	.reporting-line-selector-button__selected-n {
		background-color: #ffffff !important;
		box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.1);
		z-index: 1;
	}

	.reporting-svg-metro-container-n {
		width: 35px;
		height: 35px;
		background-size: 35px 35px;
	}
}
