.logo-out-large {
	height: 86px;
	width: 86px;
	border: 6px solid var(--bg-color-blue);
	position: relative;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	top: 0px;
	right: 0;
	text-align: center;
	box-shadow: 0 4px 4px -2px grey;
}

.logo-out-medium {
	height: 64px;
	width: 64px;
	border: 6px solid var(--bg-color-blue);
	position: relative;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	top: 0px;
	right: 0;
	text-align: center;
	box-shadow: 0 4px 4px -2px grey;
}

.logo-out-small {
	height: 46px;
	width: 46px;
	border: 6px solid var(--bg-color-blue);
	position: relative;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	top: 0px;
	right: 0;
	text-align: center;
	box-shadow: 0 4px 4px -2px grey;
}

.square-logo-out {
	border-radius: 16px !important;
}

#nav-phone .logo-out-large {
	top: -5px;
	height: 86px;
	width: 86px;
	box-shadow: 0 4px 4px -2px grey;
}

#nav-phone .logo-in {
	height: 42px;
	width: 42px;
}
