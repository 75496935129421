.show-mobile {
  display: none;
}

.action-close {
  display: flex;
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  cursor: pointer;
}

.pre-wrap {
  white-space: pre-wrap;
}

@media (max-width: 992px) {
  .action-close-arrow {
    cursor: pointer;
    position: absolute;
    left: 24px;
    padding: 0px 10px 0px 0px;
  }
  .show-mobile {
    display: block;
  }
}
