.title-container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;
  text-align: center;
}
.title-container > h4 {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 300;
  margin-top: 1rem;
  color: var(--text-color-blue);
}

.margin-header {
  margin-top: 30px;
}

.title-container > h3 {
  font-size: 22px;
  margin-top: 12px;
  color: var(--text-color-green);
}

/* page specific */
.success-page .title-container > h3 {
  color: white;
  font-size: 24px;
}

.success-page .title-container > h4 {
  margin-top: 25px;
}

/* responsiveness */
@media (max-height: 576px) {
  .confirmation-page .title-container > h4 {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .confirmation-page .title-container > h3 {
    font-size: 20px;
  }
}
