.ok-btn {
  width: 100%;
  height: 94px;
  background: #00AA91;
  border-radius: 16px;
  opacity: 1;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ok-btn:disabled {
  height: 94px;
  background: #dddddd 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  border: none;
  box-shadow: none;
}

.ok-label {
  text-align: center;
  font-weight: bold;
  font-size: 34px;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  margin: 8px;
}

.disabled {
  color: #b4b4b4 !important;
}

.play-icon {
  margin: 8px;
}

.play-icon-disabled {
  margin: 8px;
}

.ok-btn > .white-card > .play-icon path {
  fill: white;
}

.ok-btn > .white-card > .play-icon-disabled path {
  fill: #b4b4b4;
}

.white-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

