@-moz-document url-prefix() {
	.credentials-header {
		margin-top: 0 !important;
	}
}

.credentials-header-container {
	width: 100%;
	background-color: white;
	position: sticky;
	top: var(--nav-height);
	z-index: 2;
	box-sizing: border-box;
	margin-bottom: 300px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.margin-custom {
	margin-bottom: 0;
}

.credentials-header-container .credentials-header {
	display: flex;
	padding: 2rem;
	padding-top: 35px;
}

.credentials-header-container .header-title {
	margin-bottom: 0;
	font-family: 'Montserrat, Bold', Arial, Helvetica, sans-serif;
}

.credentials-header-container .header-subtitle {
	font-size: 12px;
}

.credentials-header-container .header-bottom {
	color: #e2e2e3;
	width: 100%;
	padding: 0;
}

.header-container-action-right {
	margin-left: auto;
	display: flex;
	margin-right: 1rem;
	gap: 1rem;
}
