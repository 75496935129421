/* import global styles */

@import './constants/_fonts.css';
@import './constants/_colors.css';
@import './constants/_dimensions.css';
@import './constants/_typography.css';
@import './constants/_backgrounds.css';
@import './constants/_metro.css';

html,
body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	background-color: var(--bg-color-grey);
	box-sizing: border-box;
	font-family: 'Parisine Ptf, Bold', Arial, Helvetica, sans-serif;
	height: 100%;
	margin: 0;
	padding: 0;
	width: 100%;
}

.card {
	margin-top: 13px;
	border: none;
}

#root {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
}

main {
	align-items: stretch;
	margin-top: var(--nav-height);
	display: flex;
	flex-direction: column;
	padding: 0;
	height: calc(100vh - var(--nav-height));
}

.content {
	display: flex;
	flex-direction: column;
	padding: 1rem 5%;
	margin: 0;
	flex: 1;
	gap: 1rem;
}

.content-dashboard {
	display: flex;
	flex-direction: column;
	padding: 1.5rem 12vw;
	margin: 0;
	flex: 1;
	gap: 2rem;
	max-width: 1440px;
}

.content > * {
	width: 100%;
}

a {
	color: white;
	text-decoration: none;
}

.pointer {
	cursor: pointer;
}

.centered {
	position: fixed;
	left: 50%;
	transform: translate(-50%, -50%);
}

.centered-in-absolute {
	left: 0;
	right: 0;
	margin: 0 auto;
}

.round {
	border-radius: 50%;
	overflow: visible;
	display: flex;
	align-items: center;
	justify-content: center;
}

.capitalize {
	text-transform: capitalize;
}

.show-mobile {
	display: none;
}

.hide-xlarge-screen {
	display: none;
}

.show-xlarge-screen {
	display: block;
}

.table-body {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container-fluid {
	padding-right: 0;
	padding-left: 0;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 992px) {
	.tablette {
		display: none !important;
	}
}

@media (max-width: 992px) {
	.hide-tablette {
		display: none !important;
	}
}

@media (min-width: 576px) {
	.smartphone {
		display: none !important;
	}
}

@media (max-width: 576px) {
	.hide-smartphone {
		display: none !important;
	}
	#root {
		background-color: var(--bg-color-grey-darker);
	}
}

/* scroll-bar styling */
* {
	scrollbar-width: thin;
	scrollbar-color: grey white;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
*::-webkit-scrollbar-track {
	background: white;
}
*::-webkit-scrollbar-thumb {
	background-color: gray;
	border-radius: 20px;
	border: 3px solid white;
}
*::-webkit-inner-spin-button,
*::-webkit-calendar-picker-indicator {
	opacity: 0;
}
input[type='time']::-webkit-clear-button {
	display: none;
}
input[type='date']::-webkit-clear-button {
	display: none;
}
