.logout-container {
  height: 100vh;
  background-color: #d72f6e;
}

.logout-container-grid {
  margin-top: -225px;
  background-color: #d72f6e;
}

.logout-answer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5rem;
  margin-left: 5rem;
  padding-top: 40px;
  align-items: center;
}

.logout-answer > span {
  margin-bottom: 10px;
  font-size: 24px;
  font-style: italic;
  color: #ffffff;
  text-align: center;
}

.logout-container-button {
  margin-top: 43px;
}

.logout-container-button .card-button-container:first-child {
  margin-bottom: 36px;
}
.lg-container-fix {
  position: fixed;
  width: 100%;
}

@media (max-height: 600px) {
  .logout-answer {
    padding-top: 20px;
  }
  .logout-answer > span {
    font-size: 17px;
  }
  .logout-container-button {
    margin-top: 40px;
  }
}

@media (max-width: 576px) {
  .logout-container-grid {
    margin-top: -138px;
  }
  .logout-svg-container {
    padding-bottom: 63%;
  }
}

@media (min-width: 576px) {
  .logout-container-grid {
    margin-top: -348px;
  }
  .logout-svg-container {
    padding-bottom: 63%;
  }
}

@media (min-width: 992px) {
  .logout-container-grid {
    margin-top: -348px;
  }
  .logout-svg-container {
    padding-bottom: 63%;
  }
}

@media (min-width: 1100px) {
  .logout-container-grid {
    margin-top: -444px;
  }
  .logout-svg-container {
    padding-bottom: 63%;
  }
}

.logout-svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
