/* global style */
.tilted-btn {
	border: none;
	background-color: transparent;
	font-weight: bold;
	line-height: 30px;
	text-align: left;
	position: relative;
	white-space: pre-wrap;
	z-index: 1;
	opacity: 1;
	color: white;
}

.tilted-btn:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.tilted-btn:after,
.tilted-btn {
	box-shadow: -1px 0px 31px #00000029;
	border-radius: 16px;
}

#btn-qr-landing:after {
	transform: matrix(1, -0.07, 0.07, 1, 0, 0);
}

#btn-qr-success:after,
#btn-logout:after,
#btn-to-start:after,
#btn-done:after,
#btn-not-done:after {
	transform: matrix(1, -0.09, 0.09, 1, 0, 0);
}

#btn-to-start,
#btn-logout {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

/* btn-qr-landing variant */
#btn-qr-landing {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	text-align: center;
	font-size: 16px;
}

#btn-qr-landing,
#btn-qr-landing:after {
	width: 350px;
	height: 355px;
	background-color: var(--bg-color-jade);

}

#btn-qr-landing > .svg-container {
	width: 154px;
	height: 154px;
	background-color: white;
}

/* btn-qr-success variant */
#btn-qr-success {
	display: flex;
	flex-direction: row;
	text-align: center;
	justify-content: space-evenly;
	align-items: center;
}

#btn-qr-success,
#btn-qr-success:after {
	width: 350px;
	height: 254px;
	background-color: var(--text-color-blue);
	margin: auto;
}

#btn-qr-success > .svg-container {
	width: 70px;
	height: 70px;
	background-color: white;
}

#btn-qr-success > span {
	font-size: 34px;
}

/* btn-logout-variant */
#btn-logout {
	font-size: 34px;
}

#btn-logout,
#btn-logout:after {
	width: 190px;
	height: 138px;
	background-color: var(--text-color-green);
}

/* btn-to-start-variant */
#btn-to-start {
	font-size: 34px;
	margin-top: -138px;
	color: var(--text-color-pink);
}

#btn-to-start,
#btn-to-start:after {
	width: 190px;
	height: 138px;
	background-color: var(--bg-color-grey-darker);
}

/* responsiveness */

@media (max-width: 576px) {
	#btn-qr-landing:after,
	#btn-qr-landing {
		width: 280px;
		height: 280px;
	}

	#btn-qr-success:after,
	#btn-qr-success {
		width: 300px;
		height: 220px;
	}

	#btn-qr-landing > .svg-container:after {
		width: 200px;
		top: 180px;
	}

	#btn-qr-landing > .svg-container {
		margin-top: 20px;
	}
}

@media (max-height: 992px) {
	#btn-qr-landing:after,
	#btn-qr-landing {
		width: 300px;
		height: 300px;
	}
}
