.svg-service-symbol-1:after {
	width: 89%;
	height: 70%;
	background: url('../../icons/cleaning-icons/ordinary.svg');
}

.svg-service-symbol-1-disabled:after {
	background: url('../../icons/cleaning-icons/ordinary-disable.svg');
}

.svg-service-symbol-2:after {
	background: url('../../icons/cleaning-icons/patrimonial.svg');
}

.svg-service-symbol-2-disabled:after {
	background: url('../../icons/cleaning-icons/patrimonial-disable.svg');
}

.svg-service-symbol-3:after {
	background: url('../../icons/cleaning-icons/cleanliness.svg');
}

.svg-service-symbol-3-disabled:after {
	background: url('../../icons/cleaning-icons/cleanliness-disable.svg');
}

.svg-service-symbol-4:after {
	background: url('../../icons/cleaning-icons/graffiti.svg');
}

.svg-service-symbol-4-disabled:after {
	background: url('../../icons/cleaning-icons/graffiti-disable.svg');
}

.svg-service-symbol-8:after {
	background: url('../../icons/cleaning-icons/ReinforcedCleaning.svg');
}

.svg-service-symbol-8-disabled:after {
	background: url('../../icons/cleaning-icons/ReinforcedCleaning-disable.svg');
}
.svg-service-symbol-11:after {
	background: url('../../icons/cleaning-icons/windshield-bis.svg');
}

.svg-service-symbol-11-disabled:after {
	background: url('../../icons/cleaning-icons/windshield-bis-disabled.svg');
}

.svg-thanks-symbol:after {
	background: url('../../icons/faces/thanks.svg');
}

.svg-incident-symbol-0-sm:after {
	background: url('../../icons/cleaning-icons/graffiti-sm.svg');
}

.svg-incident-symbol-0-sel:after {
	background: url('../../icons/cleaning-icons/graffiti-sel.svg');
}

.svg-incident-symbol-1-sm:after {
	background: url('../../icons/cleaning-icons/incident-di.svg');
}

.svg-incident-symbol-1-sel:after {
	background: url('../../icons/cleaning-icons/incident-di-sel.svg');
}

.svg-incident-symbol-2-sm:after {
	background: url('../../icons/cleaning-icons/windshield-sm.svg');
}
.svg-incident-symbol-2-sel:after {
	background: url('../../icons/cleaning-icons/windshield-sel.svg');
}

.svg-incident-symbol-2:after {
	background: url('../../icons/cleaning-icons/windshield-bis-sm.svg');
}

.svg-incident-symbol-3:after {
	background: url('../../icons/cleaning-icons/stain.svg');
}

.svg-incident-symbol-4:after {
	background: url('../../icons/cleaning-icons/tag.svg');
}

.svg-incident-symbol-5:after {
	background: url('../../icons/cleaning-icons/graffiti.svg');
}

.svg-incident-symbol-5-disabled:after {
	background: url('../../icons/cleaning-icons/graffiti-disable.svg');
}

.svg-service-symbol-1-sm:after {
	background: url('../../icons/cleaning-icons/ordinary-sm.svg');
}

.svg-service-symbol-1-sel:after {
	background: url('../../icons/cleaning-icons/ordinary-sel.svg');
}

.svg-service-symbol-1-disabled-sm:after {
	background: url('../../icons/cleaning-icons/ordinary-disable-sm.svg');
}

.svg-service-symbol-2-sm:after {
	background: url('../../icons/cleaning-icons/patrimonial-sm.svg');
}

.svg-service-symbol-2-sel:after {
	background: url('../../icons/cleaning-icons/patrimonial-sel.svg');
}

.svg-service-symbol-2-disabled-sm:after {
	background: url('../../icons/cleaning-icons/patrimonial-disable-sm.svg');
}

.svg-service-symbol-3-sm:after {
	background: url('../../icons/cleaning-icons/cleanliness-sm.svg');
}

.svg-service-symbol-3-sel:after {
	background: url('../../icons/cleaning-icons/cleanliness-sel.svg');
}

.svg-service-symbol-3-disabled-sm:after {
	background: url('../../icons/cleaning-icons/cleanliness-disable-sm.svg');
}

.svg-service-symbol-4-sm:after {
	background: url('../../icons/cleaning-icons/graffiti-sm.svg');
}

.svg-service-symbol-4-disabled-sm:after {
	background: url('../../icons/cleaning-icons/graffiti-disable.svg');
}

.svg-service-symbol-8-sm:after {
	background: url('../../icons/cleaning-icons/ReinforcedCleaning-sm.svg');
}

.svg-service-symbol-8-sel:after {
	background: url('../../icons/cleaning-icons/ReinforcedCleaning-sel.svg');
}

.svg-service-symbol-8-disabled-sm:after {
	background: url('../../icons/cleaning-icons/ReinforcedCleaning-disable-sm.svg');
}

.svg-service-symbol-11-sm:after {
	background: url('../../icons/cleaning-icons/windshield-bis-sm.svg');
}

.svg-service-symbol-11-sel:after {
	background: url('../../icons/cleaning-icons/windshield-bis-sel.svg');
}

.svg-service-symbol-11-disabled-sm:after {
	background: url('../../icons/cleaning-icons/windshield-bis-disabled-sm.svg');
}

.svg-incident-symbol-3-sm:after {
	background: url('../../icons/cleaning-icons/stain-sm.svg');
}

.svg-incident-symbol-4-sm:after {
	background: url('../../icons/cleaning-icons/tag-sm.svg');
}

.svg-incident-symbol-5-sm:after {
	background: url('../../icons/cleaning-icons/graffiti-sm.svg');
}

.svg-incident-symbol-5-disabled-sm:after {
	background: url('../../icons/cleaning-icons/graffiti-disable.svg');
}
.svg-incident-symbol-1:after,
.svg-incident-symbol-bm:after {
	background: url('../../icons/cleaning-icons/incident-bm.svg');
}
.svg-incident-symbol-1-lg:after,
.svg-incident-symbol-bm-lg:after {
	background: url('../../icons/cleaning-icons/incident-bm-lg.svg');
}
.svg-incident-symbol-2:after,
.svg-incident-symbol-pb:after {
	background: url('../../icons/cleaning-icons/incident-pb.svg');
}
.svg-incident-symbol-2-lg:after,
.svg-incident-symbol-pb-lg:after {
	background: url('../../icons/cleaning-icons/incident-pb-lg.svg');
}
.svg-incident-symbol-3:after,
.svg-incident-symbol-sr:after {
	background: url('../../icons/cleaning-icons/incident-sr.svg');
}
.svg-incident-symbol-di:after {
	background: url('../../icons/cleaning-icons/incident-sr.svg');
}
.svg-incident-symbol-3-lg:after,
.svg-incident-symbol-sr-lg:after {
	background: url('../../icons/cleaning-icons/incident-sr-lg.svg');
}
.svg-incident-symbol-di-lg:after {
	background: url('../../icons/cleaning-icons/incident-sr-lg.svg');
}
.svg-incident-symbol-4:after,
.svg-incident-symbol-tg:after {
	background: url('../../icons/cleaning-icons/incident-tg.svg');
}
.svg-incident-symbol-4-lg:after,
.svg-incident-symbol-tg-lg:after {
	background: url('../../icons/cleaning-icons/incident-tg-lg.svg');
}
.svg-incident-symbol-5:after,
.svg-incident-symbol-df:after {
	background: url('../../icons/cleaning-icons/incident-df.svg');
}
.svg-incident-symbol-gr:after {
	background: url('../../icons/cleaning-icons/incident-df.svg');
}
.svg-incident-symbol-5-lg:after,
.svg-incident-symbol-df-lg:after {
	background: url('../../icons/cleaning-icons/incident-df-lg.svg');
}
.svg-incident-symbol-gr-lg:after {
	background: url('../../icons/cleaning-icons/incident-df-lg.svg');
}
.svg-incident-symbol-np:after {
	background: url('../../icons/cleaning-icons/incident-np.svg');
}
.svg-incident-symbol-op:after {
	background: url('../../icons/cleaning-icons/incident-op.svg');
}

.svg-service-symbol-1,
.svg-service-symbol-1:after,
.svg-service-symbol-1-disabled:after {
	width: 110px !important;
	height: 110px !important;
	background-size: 110px 110px !important;
	margin-top: 0px !important;
}

.svg-incident:after {
	content: '\A';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.svg-service:after {
	width: 140px;
	height: 65px;
	background-size: 140px 65px;
	content: '\A';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0px auto auto auto;
}

.svg-service-confirmation:after {
	width: 150px;
	height: 150px;
	background-size: 150px 150px;
	content: '\A';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-top: -25px;
}

.svg-confirmation:after {
	width: 100px;
	height: 100px;
	background-size: 100px 100px;
	content: '\A';
	position: absolute;
	left: 40%;
}

.confirmation-page .svg-service-container {
	width: 100px;
	height: 100px;
	position: relative;
}

.svg-incident-container {
	width: 40px;
	height: 40px;
	position: relative;
	background: #f9f9fa;
	border-radius: 4px;
	margin-left: 2px;
	box-shadow: inset 0 0 1px #000000;
}

.svg-incident-lg-container {
	width: 60px;
	height: 60px;
	position: relative;
	background: #f9f9fa;
	border-radius: 2px;
}

.svg-thanks-container {
	width: 174px;
	height: 144px;
	position: relative;
	border-radius: 4px;
	margin-left: 2px;
	box-shadow: inset 0 0 1px #000000;
}
