.title_status {
  font-size: 11px;
  width: 57px;
  height: 16px;
  border-radius: 2px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_done {
  background-color: var(--bg-color-blue);
}

.title_todo {
  background-color: #aa0700;
}

.title_in_progress {
  background-color: #de4e00;
}
