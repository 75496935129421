.sucess-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg-color-jade);
  z-index: 1021;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.success-page__title,
.success-page__icon,
.success-page__counter {
  flex-grow: 1;
}

.success-page__counter {
  font-size: 60px;
  color: white;
}

.success-page__title {
  font-size: 28px;
  margin-top: 73px;
  color: #ffffff;
}
